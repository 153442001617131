import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Text, useTooltip, HelpIcon } from '@plearn/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { FOUNDING_POOL_ID, LOCKED_POOL_ID } from 'config/constants/pools'
import { useFetchPublicPoolsData, usePools, useFetchUserPools, useCakeVault } from 'state/pools/hooks'
import { usePollFarmsPublicData } from 'state/farms/hooks'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { DeserializedPool } from 'state/types'
import { useUserPoolStakedOnly, useUserPoolsViewMode } from 'state/user/hooks'
import { ViewMode } from 'state/user/actions'
import Loading from 'components/Loading'
import PoolCard from './components/PoolCard'
import CakeVaultCard from './components/CakeVaultCard'
import PoolTabButtons from './components/PoolTabButtons'
// import BountyCard from './components/BountyCard'
// import HelpButton from './components/HelpButton'
import PoolsTable from './components/PoolsTable/PoolsTable'
import { getAprData, getCakeVaultEarnings } from './helpers'

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const PoolControls = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 0px;
    margin-bottom: 0;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`

const NUMBER_OF_POOLS_VISIBLE = 12

const Pools: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools()
  const [stakedOnly, setStakedOnly] = useUserPoolStakedOnly()
  const [viewMode, setViewMode] = useUserPoolsViewMode()
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    // const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
    // const cakeAutoVault = { ...cakePool, isAutoVault: true }
    return [...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyOpenPools = useMemo(
    () =>
      openPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [openPools, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0
  const showFinishedPools = location.pathname.includes('history')

  const foundingInvestorPools = pools.filter((pool) => FOUNDING_POOL_ID.includes(pool.sousId))
  const isFoundingInvestor = showFinishedPools
    ? foundingInvestorPools.find((pool) => {
        return pool.userData.isFoundingInvestor === true && pool.isFinished
      })
    : foundingInvestorPools.find((pool) => {
        return pool.userData.isFoundingInvestor === true
      })

  usePollFarmsPublicData()
  // useFetchCakeVault()
  useFetchPublicPoolsData()
  useFetchUserPools(account)

  useEffect(() => {
    if (isIntersecting) {
      setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
        if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
          return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
        }
        return poolsCurrentlyVisible
      })
    }
  }, [isIntersecting])

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: DeserializedPool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: DeserializedPool) => {
            let totalStaked = Number.NaN
            if (pool.isAutoVault) {
              if (totalCakeInVault.isFinite()) {
                totalStaked = +formatUnits(
                  ethers.BigNumber.from(totalCakeInVault.toString()),
                  pool.stakingToken.decimals,
                )
              }
            } else if (pool.sousId === 0) {
              if (pool.totalStaked?.isFinite() && totalCakeInVault.isFinite()) {
                const manualCakeTotalMinusAutoVault = ethers.BigNumber.from(pool.totalStaked.toString()).sub(
                  totalCakeInVault.toString(),
                )
                totalStaked = +formatUnits(manualCakeTotalMinusAutoVault, pool.stakingToken.decimals)
              }
            } else if (pool.totalStaked?.isFinite()) {
              totalStaked = +formatUnits(ethers.BigNumber.from(pool.totalStaked.toString()), pool.stakingToken.decimals)
            }
            return Number.isFinite(totalStaked) ? totalStaked : 0
          },
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  let chosenPools
  if (showFinishedPools) {
    chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools
  } else {
    chosenPools = stakedOnly ? stakedOnlyOpenPools : openPools
  }

  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools = chosenPools.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }

  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible)
  chosenPoolsLength.current = chosenPools.length

  const activePools = chosenPools.filter(
    (pool) => !FOUNDING_POOL_ID.includes(pool.sousId) && !LOCKED_POOL_ID.includes(pool.sousId),
  )

  const showLockedPools = useMemo(() => {
    const ids = finishedPools.map((obj) => obj.sousId)
    return !LOCKED_POOL_ID.every((id) => ids.includes(id))
  }, [finishedPools])

  const cardLayout = (
    <CardLayout>
      {chosenPools
        .filter((pool) => !FOUNDING_POOL_ID.includes(pool.sousId))
        .map((pool) =>
          pool.isAutoVault ? (
            <CakeVaultCard key="auto-cake" pool={pool} showStakedOnly={stakedOnly} />
          ) : (
            <PoolCard key={pool.sousId} pool={pool} account={account} />
          ),
        )}
    </CardLayout>
  )

  const cardFounderPoolLayout = (
    <CardLayout>
      <PoolCard key={2} pool={chosenPools.find((pool) => FOUNDING_POOL_ID.includes(pool.sousId))} account={account} />
    </CardLayout>
  )

  const cardLockedPoolLayout = (
    <CardLayout>
      <PoolCard
        key={3}
        pool={chosenPools.find(
          (pool) => !FOUNDING_POOL_ID.includes(pool.sousId) && LOCKED_POOL_ID.includes(pool.sousId),
        )}
        account={account}
      />
    </CardLayout>
  )

  const tableLayout = (
    <PoolsTable
      pools={chosenPools.filter(
        (pool) => !FOUNDING_POOL_ID.includes(pool.sousId) && !LOCKED_POOL_ID.includes(pool.sousId),
      )}
      account={account}
      userDataLoaded={userDataLoaded}
    />
  )
  const tableFounderPoolLayout = (
    <PoolsTable
      pools={chosenPools.filter((pool) => FOUNDING_POOL_ID.includes(pool.sousId) && pool.userData.isFoundingInvestor)}
      account={account}
      userDataLoaded={userDataLoaded}
    />
  )

  const tableLockedPoolLayout = (
    <PoolsTable
      pools={chosenPools.filter(
        (pool) => !FOUNDING_POOL_ID.includes(pool.sousId) && LOCKED_POOL_ID.includes(pool.sousId),
      )}
      account={account}
      userDataLoaded={userDataLoaded}
    />
  )

  const {
    targetRef: highyieldTargetRef,
    tooltip: highyieldTooltip,
    tooltipVisible: highyieldTooltipVisible,
  } = useTooltip(
    <Text color="black">
      {t(
        'High-yield Pool is a locked pool, when you unstake, you will receive PLN after the displayed (7 days or 21 days) locked period.',
      )}
    </Text>,
    {
      placement: 'top',
    },
  )

  return (
    <>
      <PageHeader background="none">
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="primary" mt="34px" mb="14px">
              {t('Pools')}
            </Heading>
            <Heading scale="md" color="text">
              {t('Stake PLN, earn free PLN tokens.')}
            </Heading>
            {/* <Heading scale="md" color="text">
              {t('High APR, low risk.')}
            </Heading> */}
          </Flex>
          {/* <Flex flex="1" height="fit-content" justifyContent="center" alignItems="center" mt={['24px', null, '0']}> */}
          {/* <HelpButton /> */}
          {/* <BountyCard /> */}
          {/* </Flex> */}
        </Flex>
      </PageHeader>
      <Page>
        {/* <PoolControls>
          <PoolTabButtons
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            hasStakeInFinishedPools={hasStakeInFinishedPools}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
          <FilterContainer>
            <LabelWrapper>
              <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
                {t('Sort by')}
              </Text>
              <ControlStretch>
                <Select
                  options={[
                    {
                      label: t('Hot'),
                      value: 'hot',
                    },
                    {
                      label: t('APR'),
                      value: 'apr',
                    },
                    {
                      label: t('Earned'),
                      value: 'earned',
                    },
                    {
                      label: t('Total staked'),
                      value: 'totalStaked',
                    },
                  ]}
                  onOptionChange={handleSortOptionChange}
                />
              </ControlStretch>
            </LabelWrapper>
            <LabelWrapper style={{ marginLeft: 16 }}>
              <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
                {t('Search')}
              </Text>
              <SearchInput onChange={handleChangeSearchQuery} placeholder="Search Pools" />
            </LabelWrapper>
          </FilterContainer>
        </PoolControls> */}

        <PoolControls>
          <FilterContainer>
            <LabelWrapper>{/* <Textbold> {t('')} </Textbold> */}</LabelWrapper>
          </FilterContainer>
          <PoolTabButtons
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            hasStakeInFinishedPools={hasStakeInFinishedPools}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
        </PoolControls>
        {showFinishedPools && (
          <Text fontSize="20px" color="failure" pb="32px">
            {t('These pools are no longer distributing rewards. Please unstake your tokens.')}
          </Text>
        )}
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        {isFoundingInvestor && (
          <>
            <Text fontSize="24px" bold mb="8px">
              {t('Founding Investor')}
            </Text>
            {tableFounderPoolLayout}
          </>
        )}

        <>
          {(showLockedPools || (!showLockedPools && showFinishedPools)) && (
            <Text fontSize="24px" bold mt="18px" mb="8px">
              {t('High-yield Pool')}
              <span ref={highyieldTargetRef}>
                <HelpIcon color="textSubtle" width="20px" height="20px" ml="6px" mb="-3px" />
              </span>
              {highyieldTooltipVisible && highyieldTooltip}
            </Text>
          )}

          {tableLockedPoolLayout}
        </>

        {activePools.length > 0 && (
          <Text fontSize="24px" bold mt="18px" mb="8px">
            {t('Standard Pool')}
          </Text>
        )}
        {tableLayout}
        <div ref={observerRef} />
        {/* <Image
          mx="auto"
          mt="12px"
          src="/images/decorations/3d-syrup-bunnies.png"
          alt="Pancake illustration"
          width={192}
          height={184.5}
        /> */}
      </Page>
    </>
  )
}

export default Pools
