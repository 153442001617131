// 97 is testnet
// 56 is mainnet
export default {
  masterChef: {
    97: '0x7604914F8B69D05926D2b5D4363296b9227b8ae2',
    56: '0x40E6F70F5B20b5108AE181753ba7E7FF1e9dAE74',
    80001: '0x6eE95E953621F2064f5BFd077490a79Eede2e823',
    137: '0xcBFe9C7415a06aC536a157ce6250C09aB4037C43',
    5: '0x6eE95E953621F2064f5BFd077490a79Eede2e823',
    1: '0x3c85820346ff20Cd37a13766BD21e04e6cef6447',
  },
  sousChef: {
    97: '',
    56: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  smartChefFoundingInvestor: {
    97: '0xb74358a0b670eD18e87F432a78e563ca0f3547A5',
    56: '0x12d798f040416Cc75bf5a37a26549f68461380fC',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  pancakeBusdBnbLP: {
    97: '0xc530E574cf82F4d5de039987dB3f304F4f018b07',
    56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  definixBusdSixLP: {
    97: '0xe8963512D6c6E1f8b4320ab8a0F2898cF689B73a',
    56: '0xea79E7d41D143050c23c1E01AC2990a506ef060A',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  lotteryV2: {
    97: '',
    56: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  lucky: {
    97: '0x15c36602024cE7f087bCc3838e42155B0fa4F125',
    56: '0xeD7622B658e027c26f1A8A2c6EF1ba6d3A16978f',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  luckyV2: {
    97: '0x059913789d257Efad4c129ffa57c6928F0c5AbD0', // Lucky Draw Contract testnet
    56: '0x1b9F506Eb099821F2F05ff9eeB6665e5aeA0AEaF',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  confirmBracket: {
    97: '0x7d7A52E4677d333bF96BEa9C1C395F2639cb9d17',
    56: '0x819Faaa7C41BeAA30a78378294648C7bf00c5001',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  multiCall: {
    56: '0xc04262CAbd025D002437257908d4B57A19766789',
    97: '0xAd5764ce2945fB001072bb9c225d078A56f6937c',
    80001: '0x7327932d839826b0E93A9Aa046B029D819965A52',
    137: '0x6fA23e511b9C0188d3B121d56b09Fd217cE82778',
    5: '0x07Fb21C072359fd33DA55Ed0729182F608e16841',
    1: '0xf51c533D94E7F745852A9Ab1E0ECbb4dDa9545f9',
  },
  plearnProfile: {
    56: '0x0021964cADF114496F296eB5328D98471c123D4f',
    97: '0xbAe29EE80bAd447193c55E821fCd30218958D9EE',
    80001: '0xbAe29EE80bAd447193c55E821fCd30218958D9EE',
    137: '',
    5: '',
    1: '',
  },
  pancakeRabbits: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  claimRefund: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  pointCenterIfo: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  tradingCompetition: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  easterNft: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  cakeVault: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  predictions: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  chainlinkOracle: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  bunnySpecialCakeVault: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  bunnySpecialPrediction: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  bunnySpecialLottery: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  farmAuction: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  AnniversaryAchievement: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  nftMarket: {
    56: '0xa69238CAf5CBadf64E8a8baB952890a41acaf45F',
    97: '0x7E840b6Fa0ee72CdE52c3E6352192df94A259D13',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  nftSale: {
    56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  pancakeSquad: {
    56: '',
    97: '',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  plearnNFT1Handler: {
    56: '0xF029EAa8807C7490313b18C49B2081d7C7a75bAE',
    97: '0xB1da9C9DcF1192AC070a779e91F254E46F5a5cAF',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  plearnNFTMint: {
    56: '0x9f545eaf5fFc3A69B9b5Ffd53f7B39A549fB28cd',
    97: '0xE5Ad1582A52eB290C7cF8De34cC388ccA36a32BB',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  plearnProfileNFT: {
    56: '0x8E87400f0B9C7700987279F9cE503dc03d913600',
    97: '0x2581453FAcf2A3497a80Fc065d05Bfac4B7C78A0',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
  plearnWhiskeyNFT: {
    56: '0x051d162895bCAA8246f60F22f4F103b07cE12AEe',
    97: '0x878AB6FeeB3ff9091D01287E33C3E45a1e72AC0e',
    80001: '',
    137: '',
    5: '',
    1: '',
  },
}
