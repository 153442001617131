import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL, DEFAULT_GAS_LIMIT, DEFAULT_GAS_LIMIT_HIGH_YIELD_POOL } from 'config'
import { calculateGasMargin } from 'utils'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  if (pid === 0) {
    const tx = await masterChefContract.enterStaking(value, { ...options, gasPrice })
    const receipt = await tx.wait()
    return receipt.status
  }

  const tx = await masterChefContract.deposit(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  if (pid === 0) {
    const tx = await masterChefContract.leaveStaking(value, { ...options, gasPrice })
    const receipt = await tx.wait()
    return receipt.status
  }

  const tx = await masterChefContract.withdraw(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestFarm = async (masterChefContract, pid) => {
  const gasPrice = getGasPrice()
  if (pid === 0) {
    const tx = await masterChefContract.leaveStaking('0', { ...options, gasPrice })
    const receipt = await tx.wait()
    return receipt.status
  }

  const tx = await masterChefContract.deposit(pid, '0', { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}


// Locked Farm
export const stakeLockedFarm = async (lockFarmContract, amount) => {
//   const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  const gasEstimate = await lockFarmContract.estimateGas.deposit(value)
  const gasLimit = calculateGasMargin(gasEstimate)
  const gasLimitOptions = {
    gasLimit,
  }

  const tx = await lockFarmContract.deposit(value, { ...gasLimitOptions })
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeLockedFarm = async (lockFarmContract, amount) => {
//   const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  const gasEstimate = await lockFarmContract.estimateGas.withdraw(value)
  const gasLimit = calculateGasMargin(gasEstimate)
  const gasLimitOptions = {
    gasLimit,
  }

  const tx = await lockFarmContract.withdraw(value, { ...gasLimitOptions })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestLockedFarm = async (lockFarmContract) => {
//   const gasPrice = getGasPrice()
const gasEstimate = await lockFarmContract.estimateGas.deposit('0')
const gasLimit = calculateGasMargin(gasEstimate)
const gasLimitOptions = {
    gasLimit,
  }
  const tx = await lockFarmContract.deposit('0', { ...gasLimitOptions })
  const receipt = await tx.wait()
  return receipt.status
}
