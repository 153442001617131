export const GRAPH_API_PROFILE = process.env.REACT_APP_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION = process.env.REACT_APP_GRAPH_API_PREDICTION
export const GRAPH_API_LUCKY = process.env.REACT_APP_GRAPH_API_LUCKY
export const SNAPSHOT_VOTING_API = process.env.REACT_APP_SNAPSHOT_VOTING_API
export const SNAPSHOT_BASE_URL = process.env.REACT_APP_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.REACT_APP_API_PROFILE
export const API_NFT = process.env.REACT_APP_API_NFT
export const API_PLEARN_NFT = process.env.REACT_APP_API_PLN_NFT
export const BASE_API =  process.env.REACT_APP_PLN_BASE_API
export const API_USER_WALLET = process.env.REACT_APP_API_USER_WALLET
export const API_CONFIRM_EMAIL_APIKEY = process.env.REACT_APP_CONFIRM_EMAIL_APIKEY
export const API_CONFIRM_EMAIL_APIURL = process.env.REACT_APP_CONFIRM_EMAIL_APIURL
export const API_NFT_MARKET_EMAIL_APIURL = process.env.REACT_APP_NFT_MARKET_EMAIL_APIURL
export const API_NFT_MARKET_EMAIL_APIKEY = process.env.REACT_APP_NFT_MARKET_EMAIL_APIKEY
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`
export const BITQUERY_API = 'https://graphql.bitquery.io'

// For getting the top 10 ranking of PLN Holder in PLN pools.
export const API_PLEARN_POOLRANKING = process.env.REACT_APP_GRAPH_API_PLEARN_POOLRANKING
/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = process.env.REACT_APP_GRAPH_API_INFO
export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const GRAPH_API_NFTMARKET = process.env.REACT_APP_GRAPH_API_NFT_MARKET

export const LUCKY_ROUND_V2 = process.env.REACT_APP_LUCKY_ROUND_V2
