import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'PLN',
    lpAddresses: {
      5: '0x69cB19A456858364b5e411b7517D35748e32B48F',
      1: '0xBe0D3526fc797583Dada3F30BC390013062A048B',
    },
    token: serializedTokens.earn,
    quoteToken: serializedTokens.weth,
  },
  // Locked Farm
  {
    pid: 1,
    lpSymbol: 'PLN-USDC LP',
    lpAddresses: {
      5: '0xcD7Cf67779C2de12A22b9A79cd66Bc0dAf73fD69',
      1: '0xD1639A4084188dD9335243f45051aa6592983cdf',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.usdc,
    contractAddress: {
      5: '0x08542DD0cb1aa08877f045005a716Eb7Ac005dd7',
      1: '0x33F7C9666c19f6d6A4DD45C96d7Abe500f43Cf70',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      5: '0xFe2dc16B9e11ECb8f99FFa409Aa233A0403f0E32',
      1: '0x6c6AC68c19816ea16E00dB438b74Bb5cCf7b19ab',
    },
  },
  {
    pid: 2,
    lpSymbol: 'PLN-ETH LP',
    lpAddresses: {
      5: '0x5CAA0a5cf3e69bF1A1447B4Da1Bd73770934EAF9',
      1: '0x28E48f1c2A2b6582cD6a05dD268AD26C2af3b74f',
    },
    token: serializedTokens.plearn,
    quoteToken: serializedTokens.weth,
    contractAddress: {
      5: '0xF49f4989a1aEe0f12c32aD6500Cb98DDeeC05D60',
      1: '0x97630FC7a52A924a949D1637249AB36644B5e377',
    },
    isLocked: true,
    lockPeriod: '3 days',
    poolWeightLockedFarm: '0',
    multiplierLockedFarm: '0',
    pendingWithdrawalContractAddress: {
      5: '0x5c89E60b5Fa839dbC659201FF6DC1274c0027dD9',
      1: '0x38b5c0fB6820638445bE1CD211fcbfF0099Be63E',
    },
  },
]

export default farms
