import { request, gql } from 'graphql-request'
import { API_PLEARN_POOLRANKING, BASE_API } from 'config/constants/endpoints'
import { StakeTokenData } from './types'

// const getGraphPlearnPoolRankingByTier = async (stakeToken : string, tier: string): Promise<StakeTokenData> => {
//   console.log(`getGraphPlearnPoolRankingByTier: ${tier}`)
//   try {
//     const response = await request(
//       API_PLEARN_POOLRANKING,
//       gql`
//         query getRankings($stakeToken: String, $tier: String) {
//           stakeToken(id: $stakeToken) {
//             id
//             rankings(first: 500, orderBy: totalStaked, orderDirection: desc, where: { tier: $tier }) {
//               user
//               totalStaked
//               rank
//               tier {
//                 name
//               }
//             }
//           }
//         }
//       `,
//       { stakeToken, tier },
//     )
//     return response.stakeToken
//   } catch (error) {
//     console.error(error)
//     return null
//   }
// }

const getTierId = (tier: string) => {
  switch (tier) {
    case "platinum":
      return 1
    case "diamond":
      return 2
    case "gold":
      return 3
    case "pearl":
      return 4
    case "investor":
      return 5
    default:
      return 1
  }
}

const getGraphPlearnPoolRankingByTier = async (stakeToken : string, tier: string): Promise<StakeTokenData> => {
  const url = `${BASE_API}/ranking/tier/${getTierId(tier)}`
  const response = await fetch(url)
  if (response.ok) {
    const json = await response.json()
    return json.data
  }
  console.error(`API: Can't fetch Plearn pool ranking by tier ${tier}`, response.status)
  return null
}

// const getGraphPlearnPoolRanking = async (stakeToken : string): Promise<StakeTokenData> => {
//   try {
//     const response = await request(
//       API_PLEARN_POOLRANKING,
//       gql`
//         query getRankings($stakeToken: String) {
//           stakeToken(id: $stakeToken) {
//             id
//             rankings(first: 1000, orderBy: totalStaked, orderDirection: desc) {
//               user
//               totalStaked
//               rank
//               tier {
//                 name
//               }
//             }
//           }
//         }
//       `,
//       { stakeToken },
//     )
//     return response.stakeToken
//   } catch (error) {
//     console.error(error)
//     return null
//   }
// }

export const getGraphPlearnPoolRanking = async (): Promise<StakeTokenData> => {
  const url = `${BASE_API}/ranking`
  const response = await fetch(url)
  if (response.ok) {
    const json = await response.json()
    return json.data
  }
  console.error(`API: Can't fetch Plearn pool ranking`, response.status)
  return null
}

const getRankingData = async (stakeToken: string, tier: string): Promise<StakeTokenData> => {
  const graphResponse =
    tier.toLowerCase() === 'all'
      ? await getGraphPlearnPoolRanking()
      : await getGraphPlearnPoolRankingByTier(stakeToken, tier)
  return graphResponse
}

export default getRankingData
