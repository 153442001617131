import { MenuItemsType } from '@plearn/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    key: 'portfolio',
    label: 'My Portfolio',
    href: '/portfolio',
    icon: 'DollarFilled',
    // hideSubNav: true,
    items: [
      {
        label: t('NFT collection'),
        href: '/portfolio/nfts',
        isNew: true,
      },
      {
        label: t('My investment'),
        href: '/portfolio/investments',
      },
    ],
  },
  // {
  //   key: 'nft',
  //   label: t('NFT'),
  //   href: `/nfts`,
  //   icon: 'Ape',
  //   isNew: true,
  //   items: [
  //     {
  //       label: t('Overview'),
  //       icon: 'Ape',
  //       href: `/nfts/overview`,
  //     },
  //     {
  //       label: t('Cap’s Apes Whisky Set'),
  //       icon: 'Whisky',
  //       href: `/nfts/collections/whiskyset`,
  //     },
  //     {
  //       label: t('Cap’s Apes Profile Picture'),
  //       icon: 'Ape',
  //       href: `/nfts/collections/capsape`,
  //     },
  //   ],
  // },
  {
    key: 'trade',
    label: t('Trade'),
    icon: 'Exchange',
    href: '/swap',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Exchange'),
        icon: 'Exchange',
        href: '/swap',
      },
      {
        label: t('Liquidity'),
        icon: 'Currency2',
        href: '/liquidity',
      },
    ],
  },
  // {
  //   label: t('Liquidity'),
  //   icon: 'Currency2',
  //   href: '/liquidity',
  //   showItemsOnMobile: false,
  //   items: [],
  // },
  {
    key: 'farm',
    label: t('Farms'),
    href: '/farms',
    icon: 'Farm2',
    // items: [],
  },
  {
    key: 'pool',
    label: t('Pools'),
    href: '/pools',
    icon: 'Pool',
    // items: [],
  },
  // {
  //   key: 'marketplace',
  //   label: t('Marketplace'),
  //   href: `/marketplace`,
  //   icon: 'MarketPlace',
  //   isNew: true,
  //   // items: [],
  // },
  {
    key: 'bridge',
    label: t('Bridge'),
    href: `/bridge`,
    icon: 'Bridge',
    isNew: true,
    // items: [],
  },
  // {
  //   label: t('Win'),
  //   href: '/prediction',
  //   icon: 'Trophy',
  //   items: [
  //     {
  //       label: t('Prediction (BETA)'),
  //       href: '/prediction',
  //     },
  //     {
  //       label: t('Lottery'),
  //       href: '/lottery',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   href: '/info',
  //   icon: 'Info2',
  //   hideSubNav: true,
  //   items: [],
  // },
  // {
  //   key: 'luckyplearn',
  //   label: 'Lucky Plearn',
  //   href: '/luckyplearn',
  //   icon: 'TicketFlat',
  //   isNew: false,
  //   hideSubNav: true,
  // },
  {
    key: 'ranking',
    label: 'Rank',
    href: '/ranking',
    icon: 'Rank',
  },
  //   {
  //     key: 'info',
  //     label: 'Info',
  //     href: '/info',
  //     icon: 'Info2',
  //     // hideSubNav: true,
  //     // items: [],
  //   },

  // {
  //   key: 'more',
  //   label: '',
  //   href: '/info',
  //   icon: 'More',
  //   // hideSubNav: true,
  //   items: [

  //   ],
  // },
]

export default config
