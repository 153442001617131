import { ResetCSS } from '@plearn/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { DatePickerPortal } from 'components/DatePicker'
import useEagerConnect from 'hooks/useEagerConnect'
import useScrollOnRouteChange from 'hooks/useScrollOnRouteChange'
import useUserAgent from 'hooks/useUserAgent'
import React, { lazy } from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchWhiskyRoundData } from 'state/nftMint/hooks'
import { useFetchPublicPoolsData } from 'state/pools/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import GlobalCheckClaimStatus from './components/GlobalCheckClaimStatus'
import PageLoader from './components/Loader/PageLoader'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import history from './routerHistory'
import GlobalStyle from './style/Global'
// Views included in the main bundle
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import Pools from './views/Pools'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import Swap from './views/Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
// const ComingSoon = lazy(() => import('./views/ComingSoon'))
// const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
// const FarmAuction = lazy(() => import('./views/FarmAuction'))
// const Ifos = lazy(() => import('./views/Ifos'))
const Info = lazy(() => import('./views/Info'))
const NftMarket = lazy(() => import('./views/Nft/market'))
const NftMintPage = lazy(() => import('./views/Nft/mint'))
// const PancakeSquad = lazy(() => import('./views/PancakeSquad'))
// const Predictions = lazy(() => import('./views/Predictions'))
// const PredictionsLeaderboard = lazy(() => import('./views/Predictions/Leaderboard'))
const ProfileCreation = lazy(() => import('./views/ProfileCreation'))
// const Proposal = lazy(() => import('./views/Voting/Proposal'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const Teams = lazy(() => import('./views/Teams'))
// const Voting = lazy(() => import('./views/Voting'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Farms = lazy(() => import('./views/Farms'))
const Home = lazy(() => import('./views/Home'))
const Liquidity = lazy(() => import('./views/Pool'))
const NotFound = lazy(() => import('./views/NotFound'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const Ranking = lazy(() => import('./views/Ranking'))
const MyInvestments = lazy(() => import('./views/MyInvestments/Investments'))
const MyNftsCollections = lazy(() => import('./views/MyInvestments/NftsCollections'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Lottery = lazy(() => import('./views/Lottery'))
const PolygonLottery = lazy(() => import('./views/Lottery/ComingSoon'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account } = useWeb3React()

  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useFetchPublicPoolsData()
  useScrollOnRouteChange()
  useUserAgent()
  useFetchWhiskyRoundData()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={[]} />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            {/* <Route exact path="/farms/auction">
              <FarmAuction />
            </Route> */}
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            {/* <Route path="/luckyplearn">
              <PolygonLottery />
            </Route> */}
            {/* <Route path="/ifo">
              <Ifos />
            </Route> */}
            {/* <Route exact path="/teams">
              <Teams />
            </Route>
            <Route path="/teams/:id">
              <Team />
            </Route> */}
            <Route path="/create-profile">
              <ProfileCreation />
            </Route>
            {/* <Route path="/competition">
              <TradingCompetition />
            </Route>
            <Route exact path="/prediction">
              <Predictions />
            </Route>
            <Route path="/prediction/leaderboard">
              <PredictionsLeaderboard />
            </Route> */}
            {/* <Route exact path="/voting">
              <Voting />
            </Route>
            <Route exact path="/voting/proposal/create">
              <CreateProposal />
            </Route>
            <Route path="/voting/proposal/:id">
              <Proposal />
            </Route> */}

            {/* NFT */}
            <Route path="/nfts">
              <NftMintPage />
            </Route>
            <Route path="/marketplace">
              <NftMarket />
            </Route>
            {/* <Route path="/pancake-squad">
              <PancakeSquad />
            </Route> */}

            {/* Info pages */}
            {/* <Route path="/info">
              <Info />
            </Route> */}

            <Route exact strict path="/farms" component={Farms} />
            <Route path="/info" component={Info} />
            <Route exact strict path="/ranking" component={Ranking} />
            <Route exact strict path="/nfts" component={NftMintPage} />
            <Route exact strict path="/portfolio/nft" component={MyInvestments} />
            <Route exact strict path="/portfolio/investments" component={MyInvestments} />
            <Route exact strict path="/portfolio/nfts" component={MyNftsCollections} />
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

            {/* Redirect */}
            <Route path="/luckydraw">
              <Redirect to="/luckyplearn" />
            </Route>
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            <Route path="/collectibles">
              <Redirect to="/nfts" />
            </Route>
            <Route path="/profile">
              <Redirect to={`/nfts/profile/${account?.toLowerCase() || ''}`} />
            </Route>
            <Route exact path="/bridge">
              {Bridge}
            </Route>
            <Route exact path="/switchToBSC">
              {BSC}
            </Route>
            <Route path="/discord">{Discord}</Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

function Bridge() {
  // 👇️ redirect to external URL
  window.location.replace(process.env.REACT_APP_PLEARN_BRIDGE)

  return null
}

function BSC() {
  // 👇️ redirect to external URL
  window.location.replace(process.env.REACT_APP_BSC_BASE_URL)
}

function Discord() {
  // 👇️ redirect to external URL
  window.location.replace(process.env.REACT_APP_PLEARN_DISCORD)

  return null
}

export default React.memo(App)
