import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const NON_FOUNDING_POOL_ID = -1
export const FOUNDING_POOL_ID = []
export const LOCKED_POOL_ID = [1, 2]
export const SIX_POOL_ID = -1

const pools: SerializedPoolConfig[] = [
  {
    sousId: 1, // High-yield Pool 21 days
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      5: '0xa8Df642096b3B641DB8E4Cc507821Fe9972AEdC9',
      1: '0x9fC8ADB092E7ff8d70688c774C86F5e3788532d1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0',
    pendingWithdrawalContractAddress: {
      5: '0x2FE00E1f0AcD2124cD2A067E2E778B6c2Eb06Fb9',
      1: '0xeE05327279a9C0c49aa5E281947CaC26b09a0944',
    },
    lockPeriod: '21 days',
    isFinished: true,
  },
  {
    sousId: 2, // High-yield Pool 7 days
    stakingToken: serializedTokens.plearn,
    earningToken: serializedTokens.plearn,
    contractAddress: {
      5: '0x9ef91342C2aA08Cd5cA5BdcF874D3c07E347150e',
      1: '0xedBD148959AFFAd7bf1e8a9a1FAeA5b0604d1114',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0',
    pendingWithdrawalContractAddress: {
      5: '0xC4c792FB3319A68E09Ca64fc0e66B32d1Ab8538A',
      1: '0x9975642248b9513f4ba86aCfA922377b44231fe6',
    },
    lockPeriod: '7 days',
    isFinished: true,
  },
]

export default pools
