/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RankingState } from 'state/types'
import { StakeTokenData, Stakeds, RankTier, CurrentRankResponse } from './types'
import getRankingData from './getRankingData'
import getCurrentUserRank from './getCurrentUserRank'

const initialRankingState: RankingState = {
  rankingData: {
    rankings: [],
  },
  tiers: null,
  currentRank: null,
  isLoading: true,
  isLoadingCurrentRank: false,
}

export const fetchRankingData = createAsyncThunk<StakeTokenData, { stakeToken: string; tier: string }>(
  'ranking/fetchRankingData',
  async ({ stakeToken, tier }) => {
    const poolRanking = await getRankingData(stakeToken, tier)
    return poolRanking
  },
)

export const fetchCurrentRank = createAsyncThunk<CurrentRankResponse, { account: string }>(
  'ranking/fetchCurrentRank',
  async ({ account }) => {
    const currentRank = await getCurrentUserRank(account)
    return currentRank
  },
)

export const RankingSlice = createSlice({
  name: 'Ranking',
  initialState: initialRankingState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers.
    setPoolRankingData: (state, action) => {
      state.rankingData = action.payload
    },
    setCurrentRank: (state, action) => {
      state.currentRank = action.payload
    },
  },
  extraReducers: (builder) => {
    // When fetchRankingData is fulfilled (finished)
    builder.addCase(fetchRankingData.fulfilled, (state, action: PayloadAction<StakeTokenData>) => {
      const rankingData = action.payload
      state.isLoading = false
      state.rankingData = rankingData
    })

    builder.addCase(fetchRankingData.pending, (state) => {
      state.isLoading = true
      state.rankingData = initialRankingState.rankingData
    })

    // When fetchRankingData is fulfilled (finished)
    builder.addCase(fetchCurrentRank.fulfilled, (state, action: PayloadAction<CurrentRankResponse>) => {
      const rankingData = action.payload?.ranking
      state.isLoadingCurrentRank = false
      state.currentRank = rankingData
      state.tiers = action.payload.tiers
    })

    builder.addCase(fetchCurrentRank.pending, (state) => {
      state.isLoadingCurrentRank = true
      state.currentRank = null
    })
  },
})

// Actions creators are generated for each case reducer function
export const { setPoolRankingData, setCurrentRank } = RankingSlice.actions

export default RankingSlice.reducer
