import { useCallback } from 'react'
import { calculateGasMargin } from 'utils'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserBalance, updateUserPendingReward, updateUserUnlockableBalance } from 'state/actions'
import { harvestFarm } from 'utils/calls'
import { BIG_ZERO } from 'utils/bigNumber'
import getGasPrice from 'utils/getGasPrice'
import { usePendingWithdrawal } from 'hooks/useContract'
import { DEFAULT_GAS_LIMIT } from 'config'
import { FOUNDING_POOL_ID } from 'config/constants/pools'

// const options = {
//   gasLimit: DEFAULT_GAS_LIMIT,
// }

const claimPendingWithdrawalLockedPool = async (pendingWithdrawal) => {
    // const gasPrice = getGasPrice()
    const gasEstimate = await pendingWithdrawal.estimateGas.withdrawExpiredLocks()
    const gasLimit = calculateGasMargin(gasEstimate)
    const gasLimitOptions = {
        gasLimit
    }
    const tx = await pendingWithdrawal.withdrawExpiredLocks({ ...gasLimitOptions })
    const receipt = await tx.wait()
    return receipt.status
  }

const useClaimPendingWithdrawalLockedPool = (sousId, pendingWithdrawalContractAddress) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const pendingWithdrawalContract = usePendingWithdrawal(pendingWithdrawalContractAddress)

  const handleClaim = useCallback(async () => {
    await claimPendingWithdrawalLockedPool(pendingWithdrawalContract)
    dispatch(updateUserUnlockableBalance(sousId, account))
    dispatch(updateUserBalance(sousId, account))
  }, [account, dispatch, pendingWithdrawalContract, sousId])

  return { onClaim: handleClaim }
}

export default useClaimPendingWithdrawalLockedPool
